import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
// import { PageHeader } from '../components/PageHeader';
import { StandardHeader } from '../components/Acf/StandardHeader';
import { FooterTopBlock } from '../components/Acf/FooterTopBlock';
import PostList from '../components/Posts/PostList';
import { decodeEntities } from '../utils/helpers';

export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const { wordpressPost: page = [], allWordpressPost, categoryFilter, wordpressWpSettings } = data;
    const { yoast, headerAcf } = page || { title: null, yoast: {}, headerAcf: {} }
    const { title: siteTitle } = wordpressWpSettings;
    const { edges: posts } = allWordpressPost

    return (
      <Layout location={location}>
        <SEO
          title={`${
            yoast.metaTitle ?
            yoast.metaTitle :
            `Blog | ${decodeEntities(siteTitle)}`
          }`}
          desc={yoast.metaDescription}
          yoast={yoast}
        />
        <StandardHeader
          title={headerAcf.title}
          content={headerAcf.content}
          location={location}
          noPaddingBottom
        />
        <PostList
          posts={posts}
          title="Latest posts"
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix={`${wordpressWpSettings.blogSlug}/`}
        />
        <FooterTopBlock useGlobalContent />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      siteUrl
    }
    wordpressPost: wordpressPage(slug: {eq: "blog"}) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      headerAcf: childWordPressAcfStandardHeader {
        title
        content
      }
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          id
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
      filter: { slug: { ne: "gatsby-demo" } }
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          featured_image_url {
            source_url
          }
          count
        }
      }
    }
  }
`
